import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/cms-template.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FilmStrip = makeShortcode("FilmStrip");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Facilities are more than just buildings for those who work in or pass through them every day. For some, they are much closer to second homes and deserve a cleaning service that reflects this importance. An FBO can often be a touch point for a VIP which could drive thousands of gallons per month in fuel sales. In this critical, competitive environment, choosing the right partners is more vital than ever before.`}</p>
    <FilmStrip photos={[{
      src: '/img/Facility_1.png',
      alt: 'Facility Care'
    }, {
      src: '/img/Facility_2.png',
      alt: 'Facility Care'
    }, {
      src: '/img/Facility_3.png',
      alt: 'Facility Care'
    }]} mdxType="FilmStrip" />
    <h2>{`Services`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Deep Clean Detail`}</strong>{` – rigorously clean and detail all hard and flat surfaces of the Lobbies, Restrooms, Line Shacks, Kitchen areas, conference rooms, pilot rooms, sleep room, and offices. In addition, Vacuum, dust, clean appliance, floor, tabletops, flat surfaces, trash removal/ trash can clean (walls behind trash can, mirror, pictures) floor throughout facility.`}</li>
      <li parentName="ul"><strong parentName="li">{`Hangar Detail`}</strong>{` – clean Hangar facilities Trash Removal, Wall cleaning, Floor tenant scrubbing, GSE cleaning, Crew Car Cleaning, Sweep, and dust.`}</li>
      <li parentName="ul"><strong parentName="li">{`Event Assistance`}</strong>{` – FoxTrot has stationed team members throughout the event to remove trash and clean up and items needed. As well as provided post event clean up.`}</li>
    </ul>
    <h2>{`Packages`}</h2>
    <p>{`Ask about our cleaning packages ranging from 7 days a week service to on demand event cleaning. No matter the frequency FoxTrot can perform even the most demanding schedules.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      